<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="navbar-container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <img id="kfaw-logo" src="@/assets/kfaw-logo.jpg" alt="Keelung for a Walk" />
      </div>
      <div class="navbar-end">
        <a v-if="$i18n.locale !== 'en'" @click="changeLanguage" class="navbar-item">
          ZH
        </a>
        <a v-if="$i18n.locale !== 'zh'" @click="changeLanguage" class="navbar-item">
          EN
        </a>
        <a v-if="isOnHome" @click="admin"
        class="navbar-item">{{$t('general.admin')}}</a>
        <a v-else @click="logout"
        class="navbar-item">{{$t('general.logout')}}</a>
      </div>
    </nav>
  </div>
</template>

<script>
import Login from '@/components/Login.vue';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['user']),
    isOnHome() {
      return this.$route?.name === 'Home'
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      if (this.$router.currentRoute.name !== 'Home') {
        this.$router.push('/');
      }
    },
    admin() {
      this.$buefy.modal.open({
        parent: this,
        component: Login,
        hasModalCard: true,
        trapFocus: true,
        ariaModal: true,
        ariaRole: 'dialog',
      });
    },
    changeLanguage() {
      if (this.$i18n.locale === 'zh') {
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = 'zh';
      }
      localStorage.setItem('language', this.$i18n.locale)
    }
  },
};
</script>

<style scoped>
#kfaw-logo {
  max-height: 60px;
}

#navbar-container {
  padding: 16px;

}

.navbar .container {
  display: flex;
}

.navbar-item {
  display: flex;
  align-items: center;
}

.navbar,
.navbar-menu,
.navbar-start,
.navbar-end {
  align-items: stretch;
  display: flex;
  padding: 0;
}

.navbar-menu {
  flex-grow: 1;
  flex-shrink: 0;
}
.navbar-start {
  justify-content: flex-start;
  margin-right: auto;
}
.navbar-end {
  justify-content: flex-end;
  margin-left: auto;
}
</style>
