<template>
  <div style="padding: 32px">
    <div v-if="hunt" style="padding-bottom: 16px">
      <b-button style="margin: 8px" @click="goToHunt()" type="is-primary">{{ $t("home.go_to_hunt") }}</b-button>
      <b-button style="margin: 8px" @click="clearCache()" type="is-info">{{ $t("home.clear_all_data") }}</b-button>
    </div>
    <div v-if="!hunt">
      <b-field  :label="$t('home.access_code_label')">
        <b-input
        @keyup.native.enter="enterCode()"
        v-model="code"
        :placeholder="$t('home.enter_code_here')"
        style="max-width: 9.65rem; margin: auto"
        rounded
        />
      </b-field>
      <b-button @click="enterCode" type="is-primary">{{ $t("general.validate") }}</b-button>
    </div>
  </div>
</template>

<script>
import { HuntService } from '@/services/hunt.service';
import { DialogProgrammatic as Dialog } from 'buefy';
import { UserService } from '@/services/user.service';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  data() {
    return {
      code: '',
      hunt: '',
      isComponentModalActive: false,
    };
  },
  created() {
    const huntItem = JSON.parse(localStorage.getItem('currentHunt'));
    if (huntItem) {
      UserService.signInAnon();
      this.hunt = huntItem;
    }
  },
  methods: {
    async enterCode() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage,
      });
      const response = await HuntService.getHuntByCode(this.code);
      loadingComponent.close();
      if (response === 'INVALID_CODE') {
        Dialog.alert({
          title: this.$t('error.wrong_access_code_title'),
          message: this.$t('error.wrong_access_code_message'),
        });
      } else if (response === 'OFFLINE') {
        Dialog.alert({
          title: this.$t('error.offline_title'),
          message: this.$t('error.offline_message'),
        });
      } else if (response === 'NO_CODES_LEFT') {
        Dialog.alert({
          title: this.$t('error.no_codes_left_title'),
          message: this.$t('error.no_codes_left_message'),
        });
      } else if (response === 'ERROR') {
        Dialog.alert({
          title: this.$t('error.unknown_title'),
          message: this.$t('error.unknown_message'),
        });
      } else { // no error
        this.hunt = response;
        localStorage.setItem('huntActivationDate', JSON.stringify(new Date()))
        this.signInNewHuntUser()
      }
    },
    async signInNewHuntUser() {
      const loading = this.$buefy.loading.open({
        container: this.isFullPage,
      });
      await UserService.newUserForHunt(this.hunt);
      localStorage.setItem('currentHunt', JSON.stringify(this.hunt));
      this.goToHunt();
      loading.close();
    },
    goToHunt() {
      this.$router.push('/hunt');
    },
    clearCache() {
      this.hunt = ''
      localStorage.removeItem('huntActivationDate')
      localStorage.removeItem('currentHunt')
      localStorage.removeItem('currentChallengeLevel')
    }
  },
};
</script>