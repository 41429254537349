import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { auth } from '@/firebaseConfig';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/hunt',
    name: 'Hunt',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Hunt.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next('/');
  } else {
    next();
  }
});

export default router;
