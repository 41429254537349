import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/firebaseConfig';
import { User } from '@/models/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {} as User,
  },
  mutations: {
    setUserProfile(state, val) {
      state.user = val;
    }
  },
  actions: {
    async logout({ commit }) {
      commit('setUserProfile', {});
      auth.signOut();
    },
    // Get user profile by uid from firebase
    async setUserProfile({ commit }, user) {
      commit('setUserProfile', user);
    },
  },
});
