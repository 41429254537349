import en from './en.json';
import zh from './zh.json';
import jp from './jp.json';

export const defaultLocale = 'zh';

export const languages = {
  en,
  zh,
  jp,
};
