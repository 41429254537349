import { huntsCollection } from "@/firebaseConfig";
import { Hunt } from "@/models/hunt";
import isOnline from "is-online";
import {
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";

const HuntService = {
  getAll: async () => {
    const hunts: Hunt[] = [];
    try {
      const q = query(huntsCollection);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((docSnapshot) => {
        const hunt = docSnapshot.data() as Hunt;
        hunt.id = docSnapshot.id;
        hunt.code = typeof hunt.code === "string" ? [hunt.code] : hunt.code;
        hunts.push(hunt);
      });
    } catch (error) {
      console.error(error);
    }
    return hunts;
  },
  getHuntByCode: async (code: string) => {
    if (!(await isOnline())) {
      return "OFFLINE";
    }
    try {
      let querySnapshot: QuerySnapshot<DocumentData, DocumentData> | undefined;

      // Check if code is not empty and perform a query assuming 'code' is a string
      if (code.trim() !== "") {
        const q = query(huntsCollection, where("code", "==", code.toLowerCase()));
        querySnapshot = await getDocs(q);
      }

      // If the query returned no results and code is empty,
      // perform a query assuming 'code' is an array
      if (!querySnapshot || querySnapshot.docs.length === 0) {
        const q = query(huntsCollection, where("code", "array-contains", code.toLowerCase()));
        querySnapshot = await getDocs(q);
      }

      // Process the query results
      if (querySnapshot.docs.length > 0) {
        const hunt = querySnapshot.docs[0].data();
        hunt.code = typeof hunt.code === "string" ? [hunt.code] : hunt.code;
        hunt.id = querySnapshot.docs[0].id;

        if (hunt.codeCount < 1) {
          return "NO_CODES_LEFT";
        }
        return hunt;
      } else {
        return "INVALID_CODE";
      }
    } catch (error) {
      console.error(error);
      return "ERROR";
    }
  },
  setHuntById: async (id: string, hunt: Hunt) => {
    try {
      const huntDocRef = doc(huntsCollection, id);
      hunt.code = typeof hunt.code === "string" ? [hunt.code] : hunt.code;
      await updateDoc(huntDocRef, {
        ...hunt,
        createdAt: hunt.createdAt, // Firestore requires Date to Timestamp
      });
      return "SUCCESS";
    } catch (error) {
      console.error(error);
      return "ERROR";
    }
  },
  addHunt: async (hunt: Hunt) => {
    try {
      hunt.code = typeof hunt.code === "string" ? [hunt.code] : hunt.code;
      const docRef = await addDoc(huntsCollection, hunt);
      return docRef.id;
    } catch (error) {
      console.error(error);
    }
    return "";
  },
  deleteHunt: async (hunt: Hunt) => {
    try {
      const huntDocRef = doc(huntsCollection, hunt.id);
      await deleteDoc(huntDocRef);
    } catch (error) {
      console.error(error);
    }
  },
};

export default HuntService;

export { HuntService };
