<template>
  <div id='app' class='container'>
    <navbar></navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar,
  },
  mounted () {
    const twoDays = 48 * 60 * 60 * 1000
    const activationDate = localStorage.getItem('huntActivationDate')
    if (activationDate &&  new Date().valueOf() > JSON.parse(activationDate).valueOf() + twoDays) {
      localStorage.removeItem('huntActivationDate')
      localStorage.removeItem('currentHunt')
      localStorage.removeItem('currentChallengeLevel')
    }
  }
};
</script>

<style lang='scss'>
@import '~bulma/sass/utilities/_all';

// Set your colors
$primary: #269484;
$primary-invert: findColorInvert($primary);
$secondary: #2594a1;
$secondary-invert: findColorInvert($secondary);
$info: #ce6362;
$info-invert: findColorInvert($info);
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'secondary': (
    $secondary,
    $secondary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 90vw;
  max-width: 1200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

</style>
