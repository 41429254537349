import Vue from 'vue';
import Buefy from 'buefy';
import VueI18n from 'vue-i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { auth } from './firebaseConfig';
import { languages, defaultLocale } from './locales/index';

document.title = 'KFAW - Treasure Hunt';
Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(Buefy);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBcvQyTwx5Z_o5SJayg4cEakukymRYvEo4',
  },
  installComponents: true,
});

const storedLocale = localStorage.getItem('language')
const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: storedLocale || defaultLocale,
  fallbackLocale: defaultLocale,
  messages,
});

let app: Vue;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
