<template>
  <div style="text-align: left;">
    <form @submit.prevent>
      <div class="modal-card" style="width: auto; max-width: 95vw; margin: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{$t('general.login')}}</p>
        </header>
        <section class="modal-card-body">
          <b-field :label="$t('login.email')">
            <b-input
              type="email"
              v-model="email"
              :placeholder="$t('login.email_placeholder')"
              @keyup.enter="login"
              required>
            </b-input>
          </b-field>
          <b-field :label="$t('login.password')">
              <b-input
                type="password"
                v-model="password"
                password-reveal
                :placeholder="$t('login.password_placeholder')"
                @keyup.enter="login"
                required>
              </b-input>
          </b-field>
          <p class="has-text-danger is-size-7" v-if="errorMsg !== ''">{{errorMsg}}</p>

        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button"
          @click="$parent.close()">{{$t('general.close')}}</button>
          <button class="button is-primary" @click="login()">{{$t('general.login')}}</button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { UserService } from '@/services/user.service';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      errorMsg: '',
    };
  },
  methods: {
    async login() {
      const loadingComponent = this.$buefy.loading.open({
        container: this.isFullPage,
      });
      const response = await UserService.login(this.email, this.password);
      if (response.email != null) {
        this.$store.dispatch('setUserProfile', response);
        this.$router.push('/admin');
        this.$parent.close();
      } else {
        this.errorMsg = response;
      }
      loadingComponent.close();
    },
  },
};
</script>
