import { auth, usersCollection, huntsCollection } from "@/firebaseConfig";
import { signInAnonymously, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { Hunt } from "@/models/hunt";

interface ErrorCode extends Error {
  code: string;
}

const UserService = {
  login: async (email: string, password: string) => {
    let errorMsg = "";
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (user != null) {
        const userDocRef = doc(usersCollection, user.uid);
        const userProfileSnap = await getDoc(userDocRef);
        return userProfileSnap.data();
      }
      errorMsg = "User was returned null from database.";
    } catch (error) {
      switch ((error as ErrorCode).code) {
        case "auth/user-not-found": {
          errorMsg = "User is unknown. Please try again!";
          break;
        }
        case "auth/wrong-password": {
          errorMsg = "Wrong password entered. Please try again!";
          break;
        }
        case "auth/user-disabled": {
          errorMsg = "This user has been disabled.";
          break;
        }
        default: {
          errorMsg = "Error. Please contact website administrator";
          break;
        }
      }
    }
    return errorMsg;
  },
  newUserForHunt: async (hunt: Hunt) => {
    const response = await signInAnonymously(auth);
    if (response.user) {
      const user = {
        id: response.user.uid,
        huntId: hunt.id,
        huntName: hunt.name,
        huntCode: hunt.code,
        createdAt: new Date(),
      };
      const userDocRef = doc(usersCollection, response.user.uid);
      const huntDocRef = doc(huntsCollection, hunt.id);
      await Promise.all([
        setDoc(userDocRef, user),
        updateDoc(huntDocRef, { codeCount: hunt.codeCount - 1 }),
      ]);
      return user;
    }
    console.error("No user credential created");
    return "error";
  },
  signInAnon: async () => {
    if (!auth.currentUser) {
      await signInAnonymously(auth);
    }
  },
};

export default UserService;

export { UserService };
